<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Nombre</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.nombre"
                                            :readonly="!isNew" />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Teléfono</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.telefono"
                                            :readonly="!isNew" />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Correo</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="email" v-model="form.state.email"
                                            :readonly="!isNew" />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Asunto</label>
                                    <div class="col-md-9">
                                        <input class="form-control" type="text" v-model="form.state.asunto"
                                            :readonly="!isNew" />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-md-3 col-form-label">Mensaje</label>
                                    <div class="col-md-9">
                                        <textarea class="form-control" rows="10" v-model="form.state.mensaje"
                                            :readonly="!isNew"></textarea>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-md-9">
                                        <SelectInputSimpleState v-model="form.state.status"></SelectInputSimpleState>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-12 text-end">
                                <div class="btn btn-primary ms-auto" @click="createContact">
                                    <i class="far fa-save"></i>
                                    Guardar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script setup>
// eslint-disable-next-line object-curly-newline
import { defineProps, ref, onMounted } from 'vue';

import WindowLayout from '@/layouts/Window.layout.vue';

import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';

import { useSnackbar } from 'vue3-snackbar';

import useContact from './services/useContact';
import { useContactForm } from './composables/useContactForm';

const props = defineProps({
    title: { type: String, default: 'Contacto' },
    headerTitle: { type: String, default: 'Revisar Contacto' },
    urlRead: { type: String, required: false },
    urlCreate: { type: String, required: false },
    urlUpdate: { type: String, required: false },
    item: Object,
});

const snackbar = useSnackbar();

const isNew = ref(true);

const service = useContact();
const { ...form } = useContactForm();

onMounted(async () => {
    if (props.item) {
        await Promise.all([service.getContact(props.item.id)]);
        if (service.contact) {
            form.setState(service.contact.value);
        }

        isNew.value = false;
    }
});

const createContact = async () => {
    if (!form.state.id) {
        await form.$v.value.$validate();
        if (!form.$v.value.error) {
            await service.createContact({
                name: form.state.nombre,
                phone: form.state.telefono,
                email: form.state.email,
                subject: form.state.asunto,
                message: form.state.mensaje
            })
                .then(() => {
                    snackbar.add({
                        type: 'success',
                        text: 'Item ha sido creado',
                    });
                })
                .catch((error) => {
                    console.log(error);
                    error.data.errors.map((e) => {
                        snackbar.add({
                            type: 'error',
                            text: e.msg,
                        });
                        return true;
                    });
                });
        } else {
            console.log(form.$v.value.$errors);
        }
    }
};
</script>
